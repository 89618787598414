<template>
  <div>
    <div class="l-header" :class="{
      light: ['grassrootsGovernanceDetail'].includes($route.name)
    }" :style="{
  height: $route.meta.headHeight,
}">
      <div class="l-header__fixed" :theme="$route.meta.headTheme">
        <img v-if="$route.meta.headTheme == 'dark'" src="@/assets/images/logo_dark.png" alt="" class="l-logo"
          style="max-width: 520rem" />
        <img v-else-if="$route.meta.headTheme == 'light'" src="@/assets/images/logo_light.png" alt="" class="l-logo" />
        <img v-else src="@/assets/images/logo.png" alt="" class="l-logo" />
        <div class="l-nav">
          <div class="l-nav__main">
            <router-link v-for="(item, index) in navList" :key="index" :to="item.path"
              :class="{ active: activeIndex == index }" @click.native="onNavChange(index)">
              {{ item.label }}
              <ul class="l-subNav" v-if="navList.length - 1 == index">
                <li v-for="(item, idx) in subNavList" :key="idx" :class="{ active: subNavActiveIndex == idx }"
                  @click="onSubNavChange(item, idx)">
                  {{ item.label }}
                </li>
              </ul>
            </router-link>
          </div>
          <template v-if="$route.meta.index != 2">
            <div v-if="isLogin" class="l-center">
              <div class="l-center__avatar">
                <img src="@/assets/images/defaulthead.png" alt="" />
              </div>
              <ul class="l-center__subNav">
                <p>
                  {{
                    userinfo.realName ? userinfo.realName : userinfo.username
                  }}
                </p>
                <li @click="tocms()">进入后台</li>
                <li @click="returnLogin()">退出登录</li>
              </ul>
            </div>
            <router-link v-else to="/login" class="l-login"
              :class="$route.path == '/communityProjectManage' ? 'cpm' : 'default'">登录</router-link>
          </template>
          <!-- <a
            class="l-login"
            style="background: initial; border: initial; cursor: initial"
          ></a> -->
        </div>
      </div>
      <template v-if="$route.meta.index == 2"></template>
      <template v-else>
        <div class="l-swiper" v-if="$route.path == '/'">
          <swiper ref="mySwiper" class="swiper" :options="options">
            <swiper-slide>
              <div class="l-swiper__item">
                <img src="@/assets/images/banner.png" alt="" class="l-swiper__item--banner" />
                <div class="l-mid">
                  <h1>聚焦人才培育</h1>
                  <h2>全力践行人才强国战略</h2>
                  <p class="desc" style="margin-top: 24rem">
                    FOCUSING ON TALENT CULTIVATION AND FULLY IMPLEMENTING THE
                    STRATEGY OF <br />
                    STRENGTHENING THE COUNTRY WITH TALENT
                  </p>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="l-swiper__item">
                <img src="@/assets/images/banner11.png" alt="" class="l-swiper__item--banner" />
                <div class="l-mid">
                  <h3>关于一湾</h3>
                  <p class="desc" style="margin-top: 36rem; max-width: 700rem">
                    一湾社会工作发展研究院旨在打造人才培养、研究实践、行业交流的多功能平台，开展分级分类人才培养，充分调动国内外社会工作领域研究机构、组织和专家力量，根据实际需求，大力推进社会工作以及社会工作人才研究、社会工作人才测评研究与服务、社会工作人才培养与评价服务、国外先进社会工作评价理论引进等项目的落地开展。以市场力量驱动社会工作人才体系开发，以科技赋能社会工作领域的研究应用推广。
                  </p>
                  <router-link style="margin-top: 80rem" class="button" to="/ptjs">
                    了解更多
                    <img src="@/assets/images/arrow_r_3.png" alt="" /></router-link>
                </div>
              </div>
            </swiper-slide>
            <!-- 分页器，注意一定要加slot -->
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="l-banner" v-else-if="$route.path == '/ptjs'">
          <transition v-if="isShowVideo" name="videofade">
            <template>
              <!-- <img v-if="isShowPlay" style="height: 40px;top: 50%;z-index: 10;" src="@/assets/images/xcpplay.svg" @click="playVideo()" /> -->
              <video id="videoXCP" ref="videoXCP" controls autoplay loop muted poster="">
                <source src="https://download.yxybb.com/project/SHGZ/yiwan/video/2024/4/23/YW_xuanchuanpian.mp4"
                  type="video/mp4" />
              </video>
            </template>
          </transition>
          <template v-else>
            <img src="@/assets/images/banner7.png" alt="" />
            <div class="l-mid">
              <div class="l-instro">
                <l-title title="简介" themeColor="white" />
                <p style="margin-top: 48rem">
                  一湾社会工作发展研究院地处粤港澳大湾区（以下简称“大湾区”）开放程度最高、经济活力最强的深圳市，广东又是我国志愿者和社会工作、社工组织的发祥地，一湾社会工作发展研究院将聚焦组织资源开展社会工作专业人才职业水平评价制度研究和社会工作者职业化管理与激励保障等领域的研究，立足深圳，面向大湾区，服务全国，努力在提升社会治理和社会服务现代化水平方面发挥一定的主体使命担当和创新应用作用。
                </p>
                <p>
                  一湾社会工作发展研究院旨在打造社会工作领域人才培养、研究实践、行业交流的多功能平台，开展分级分类人才培养，充分调动国内外社会工作领域研究机构、组织和专家力量，根据实际需求，大力推进社会工作以及社会工作人才研究、社会工作人才测评研究与服务、社会工作人才培养与评价服务、国外先进社会工作评价理论引进等项目工作。以市场力量驱动社会工作人才体系开发，以科技赋能社会工作领域的研究应用推广。
                </p>
              </div>
            </div>
          </template>
        </div>
        <img src="@/assets/images/banner10.png" alt="" class="l-banner__image" v-else-if="$route.path == '/train'" />
        <div class="l-banner" v-else-if="$route.path == '/communityProjectManage'">
          <img src="@/assets/imgs/banner3.jpg" alt="" class="l-banner__image" />
          <div class="l-mid">
            <div class="l-banner__pmcontent">
              <h1>
                社区项目通V1.0<br />
                智慧管理 高效协作
              </h1>
              <p>
                一个工具满足你：项目管理、活动控制、文档共享、协同办公、物<br />资管理、内部邮件、工作审批以及更多，让工作如此简单
              </p>
              <a-space :size="24" style="margin-top: 62rem">
                <button type="success" @click="onPmLogin">免费试用</button>
                <button @click="onPower">预约演示</button>
              </a-space>
            </div>
          </div>
        </div>
        <div class="l-banner" v-else-if="$route.path == '/grassrootsGovernance'" style="height: 820rem;">
          <img src="@/assets/images/banner13.png" alt="" class="l-banner__image" style="width: 100%;height: 820rem;" />
          <div class="l-mid">
            <div class="l-banner__pmcontent grass">
              <h1>基层强则国家强 基层安则天下安</h1>
              <p>强基固本，服务人民——《城乡基层治理实务》系列丛书，助力新时代城乡社区建设，助力构建人人有责、人人尽责、人人享有的基层治理共同体。</p>
              <a-space :size="24" style="margin-top: 20rem">
                <button type="success" @click="onBookOrder">一键征订</button>
              </a-space>
            </div>
          </div>
        </div>
        <div class="l-banner three" v-else-if="$route.name === 'grassrootsGovernanceDetail'"></div>
        <div class="l-banner" v-else>
          <img src="@/assets/images/banner2.png" alt="" />
          <div class="l-mid">
            <h1>聚焦人才培育</h1>
            <h2>全力践行人才强国战略</h2>
          </div>
        </div>
      </template>
      <img src="@/assets/images/mouse.png" alt="" class="l-header__mouse"
        v-if="$route.meta.headHeight == '964rem' && isMouseIcon" />
    </div>
    <l-pop v-model="visible">
      <div class="l-contact">
        <img src="@/assets/images/banner4.png" alt="" class="l-contact__banner" />
        <div class="l-contact__main">
          <l-title title="合作意向">
            <img slot="suffix" class="l-close" src="@/assets/images/close.png" alt="" @click="visible = false" />
          </l-title>
          <div class="l-form">
            <div class="l-form__item">
              <a-select v-model="formData.unitType" placeholder="请选择机构类型" style="width: 100%">
                <a-select-option value="社工机构">社工机构</a-select-option>
                <a-select-option value="社工协会">社工协会</a-select-option>
                <a-select-option value="行政管理部门或事业单位">行政管理部门或事业单位</a-select-option>
                <a-select-option value="培训机构">培训机构</a-select-option>
                <a-select-option value="其它机构">其它机构</a-select-option>
              </a-select>
            </div>
            <div class="l-form__item">
              <a-cascader change-on-select :load-data="loadData" @change="onChange" v-model="areaid" placeholder="请选择所在地区"
                style="width: 100%" :options="areaOption"></a-cascader>
            </div>
            <div class="l-form__item">
              <input v-model="formData.contactPhone" type="text" class="l-input" placeholder="请输入联系电话" />
            </div>
            <div class="l-form__item">
              <input v-model="formData.email" type="text" class="l-input" placeholder="请输入电子邮箱" />
            </div>
            <div class="l-form__item">
              <input v-model="formData.cooperationUnit" type="text" class="l-input" placeholder="请输入单位名称" />
            </div>
            <div class="l-form__item">
              <input v-model="formData.contactPeople" type="text" class="l-input" placeholder="请输入联系人" />
            </div>
            <div class="l-form__item" style="width: 100%">
              <textarea v-model="formData.content" cols="30" rows="10" class="l-textarea"
                placeholder="请输入合作意向"></textarea>
            </div>
          </div>
          <button @click="cooperationSubmit()" class="l-button" style="width: 344rem; margin: 40rem auto 0">
            提交
          </button>
        </div>
      </div>
    </l-pop>
  </div>
</template>

<script>
import LPop from '@/components/LPop.vue'
import LTitle from '@/components/LTitle.vue'
import { areal3Api, cooperationInsert, loginoutApi } from '@/api/sgapi'
export default {
  name: 'l-header',
  components: {
    LPop,
    LTitle,
  },
  props: {
    isSwiper: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
    },
  },
  data() {
    return {
      isMouseIcon: true,
      options: {
        loop: true,
        autoplay: {
          //swiper手动滑动之后自动轮播失效的解决方法,包括触碰，拖动，点击pagination,重新启动自动播放
          disableOnInteraction: false,
          // 自动播放时间：毫秒
          delay: 6000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          // bulletClass: "my-bullet",
        },
      },
      navList: [
        {
          path: '/',
          label: '首页',
        },
        {
          path: '/ptjs',
          label: '平台介绍',
        },
        {
          label: '社区项目通',
          path: '/communityProjectManage',
         },
        // {
        //   label: '基层治理专题',
        //   path: '/grassrootsGovernance',
        // },
        {
          label: '社会工作项目',
          path: '/projectNotice',
        },
        {
          label: '公开招聘',
          path: '/socialWork',
        },

        {
          path: '/jxjy',
          label: '精品课程',
        },

        {
          path: '/train',
          label: '考前培训',
        },
        {
          path: '',
          label: '更多',
        },
      ],
      subNavList: [
        // {
        //   label: '治理创新',
        //   path: '/innovate',
        // },
        // {
        //   label: '嘉宾访谈',
        //   path: '/interview',
        // },
        // {
        //   label: '人才培训',
        //   path: 'talentTraining',
        // },
        {
          path: '/project',
          label: '培育项目',
        },
        {
          path: '/teachers',
          label: '师资团队',
        },
        {
          path: '/cooperator',
          label: '合作单位',
        },
        // {
        //   label: '客服服务',
        //   url: 'https://im1c5366d.7x24cc.com/phone_webChat.html?accountId=N000000007880&chatId=dbbd6559-7a1d-416f-9ed6-a8309e20f84e',
        // },
        {
          label: '合作洽谈',
          visible: true,
        },
      ],
      activeIndex: 0,
      visible: false,
      // isShowPlay: true,
      isShowVideo: true,
      subNavActiveIndex: -1,
      formData: {
        cooperationUnit: null,
        areaId: null,
        contactPeople: null,
        contactPhone: null,
        email: null,
        content: null,
        unitType: undefined,
      },
      areaOption: [],
      areaFieldNames: {
        label: 'areaname',
        value: 'areaid',
        children: 'children',
      },
      areaid: [],
      isLogin: false,
      userinfo: {},
      accountinfo: {},
    }
  },
  methods: {
    cooperationSubmit() {
      var that = this
      if (this.areaid && this.areaid.length > 0) {
        this.formData.areaId = this.areaid[this.areaid.length - 1]
      }
      cooperationInsert({ ...this.formData })
        .then((res) => {
          if (res && res.code == '0') {
            that.$message.success('提交成功')
            that.formData = Object.assign(
              {},
              {
                cooperationUnit: undefined,
                areaId: null,
                contactPeople: null,
                contactPhone: null,
                email: null,
                content: null,
                unitType: null,
              }
            )
            that.visible = false
          }
        })
        .catch((e) => {
          that.$message.error('提交失败')
        })
    },
    onNavChange(index) {
      this.activeIndex = index
      if (!this.isShowVideo) {
        this.isShowVideo = true
      }
    },
    onSubNavChange(item, idx) {
      const { path, url, visible } = item
      if (path) {
        this.$router.push(path)
      } else if (url) {
        window.open(url)
      } else if (visible) {
        this.visible = visible
      }
      this.subNavActiveIndex = idx
    },
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      areal3Api({ areaId: targetOption.value, level: targetOption.level + 1 })
        .then((res) => {
          targetOption.loading = false
          if (res && res.data) {
            targetOption.children = res.data.map((el) => {
              return {
                isLeaf: el.isLeaf ? true : false,
                label: el.areaname,
                value: el.areaid,
                key: el.areaid,
                disabled: false,
                level: el.level,
              }
            })
            this.areaOption = [...this.areaOption]
          }
        })
        .catch((e) => {
          targetOption.loading = false
        })
        .finally(() => { })
    },
    onChange() { },
    tocms() {
      window.open(this.accountinfo.jumpUrl)
    },
    returnLogin() {
      this.isLogin = false
      loginoutApi()
        .then()
        .catch()
        .finally(() => {
          sessionStorage.clear()
          window.location.reload()
        })
    },
    /* playVideo() {
      if(this.$refs.videoXCP){
        this.isShowPlay = false
        this.$refs.videoXCP.play()
      }
    }, */
    onPmLogin() {
      this.$bus.emit('pmLogin')
    },
    onPower() {
      this.$bus.emit('power')
    },
    onBookOrder() {
      this.$bus.emit('bookOrder')
    },
  },
  watch: {
    $route(v) {
      if (!this.navList.some((e) => e.path == v.path)) {
        this.activeIndex = this.navList.length - 1
      }

      if (this.$route.name === 'grassrootsGovernanceDetail') {
        this.activeIndex = 3
      }
    },
  },
  created() {
    this.$router.onReady(() => {
      var hpath = this.$route.path
      const vname = this.$route.name

      if ('/kqlivelist' == this.$route.path) {
        hpath = '/train'
      }
      var i = this.navList.findIndex((el) => {
        return el.path == hpath
      })
      if (i >= 0) {
        this.activeIndex = i
      } else {
        this.activeIndex = 0
      }
      if (vname === 'grassrootsGovernanceDetail') {
        this.activeIndex = 3
      }
    })

    areal3Api()
      .then((res) => {
        if (res && res.data && res.data.length > 0) {
          this.areaOption = res.data.map((el) => {
            return {
              isLeaf: el.isLeaf ? true : false,
              label: el.areaname,
              value: el.areaid,
              key: el.areaid,
              disabled: false,
              level: el.level,
            }
          })
          // //console.log('areaOption',this.areaOption)
        }
      })
      .catch((e) => { })

    if (
      sessionStorage.getItem('GW-SGJXJY-TOKEN') &&
      sessionStorage.getItem('GW-SGJXJY-ACCOUNTINFO')
    ) {
      var accountinfo = JSON.parse(
        sessionStorage.getItem('GW-SGJXJY-ACCOUNTINFO')
      )
      if (accountinfo && accountinfo.userInfo) {
        this.accountinfo = accountinfo
        this.userinfo = accountinfo.userInfo
        this.isLogin = true
      }
    }
  },
  mounted() {
    const _this = this
    window.addEventListener('scroll', function (e) {
      //  //console.log(Number(_this.$route.meta.headHeight.match(/\d+/g)[0]));
      if (_this.$route.path != '/ptjs') {
        return;
      }
      if (window.scrollY) {
        _this.isMouseIcon = false
        if (_this.isShowVideo) {
          window.setTimeout(() => {
            document.documentElement.scrollTop = 0
            _this.isShowVideo = false
          }, 500)
        }
      } else {
        _this.isMouseIcon = true
      }
    })
  },
  beforeDestroy() {
    this.$bus.off('pmLogin')
    this.$bus.off('power')
    this.$bus.off('bookOrder')
  }
}
</script>

<style lang="less" scoped>
.videofade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.videofade-leave-to {
  opacity: 0;
}

@keyframes shake {
  0% {
    bottom: 70rem;
  }

  50% {
    bottom: 55rem;
  }

  100% {
    bottom: 70rem;
  }
}

.l {
  &-header {
    position: relative;
    z-index: 11;
    overflow: hidden;

    .l-mid {
      display: flex;
      align-items: center;
      padding: 64rem 0;
    }

    &__mouse {
      position: absolute;
      bottom: 55rem;
      left: 50%;
      transform: translateX(-50%);
      width: 88rem;
      height: 88rem;
      z-index: 11;
      animation-name: shake;
      animation-duration: 1.2s;
      animation-timing-function: ease-out;
      animation-iteration-count: infinite;
    }

    &__fixed {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 11;
      padding: 54rem 88rem;
      display: flex;
      align-items: center;

      &[theme='dark'] {
        .l-login.default {}

        .l-nav {
          &::after {
            background-color: #3F4653;
          }

          &__main {
            &::after {
              background: rgba(127, 137, 157, 0.25);
            }

            a {
              color: #2d2e33;

              &:hover {
                color: #1a8bf9;
              }

              &.active {
                color: #1a8bf9;

                &::after {
                  background: #1a8bf9;
                }
              }

              &.router-link-active {
                color: #1A1C1F;
              }
            }
          }
        }
      }
    }

    &__content {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 145rem;
    }

    &__title {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 156rem;
        color: #fff;
        font-weight: bold;
      }

      h2 {
        font-size: 92rem;
        color: #fff;
        font-weight: bold;
      }
    }

    &__suffix {
      display: flex;
      align-items: center;

      b {
        font-size: 30rem;
        color: #fff;
        font-weight: bold;
        line-height: 48rem;
      }

      span {
        height: 70rem;
        width: 1rem;
        background: #fff;
        margin: 0 60rem;
      }

      p {
        font-size: 18rem;
        color: #fff;
        line-height: 28rem;
      }
    }
  }

  &-logo {
    width: 400rem;
    vertical-align: top;
  }

  &-nav {
    display: flex;
    flex: 1;
    // overflow: hidden;
    margin-left: 80rem;
    position: relative;

    &::after {
      display: block;
      content: '';
      height: 2rem;
      background: #fff;
      opacity: 0.4;
      position: absolute;
      left: 0;
      right: -999rem;
      bottom: 0;
    }

    &__main {
      flex: 1;
      display: flex;
      justify-content: space-between;
      gap: 0 20rem;
      position: relative;

      a {
        display: inline-flex;
        align-items: center;
        font-size: 16rem;
        color: #fff;
        max-width: 6em;
        position: relative;
        transition: 0.3s;

        &:first-child {
          max-width: 2em;
        }

        &:hover {
          font-weight: 600;

          .l-subNav {
            opacity: 1;
            z-index: 11;
          }
        }

        &.active {
          font-weight: 600;

          &::after {
            display: inline-block;
            content: '';
            width: 100%;
            height: 4rem;
            background: #fff;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
          }
        }
      }
    }
  }

  &-login {
    margin-left: 42rem;
    cursor: pointer;
    width: 100rem;
    height: 84rem;


    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18rem;
    color: #fff;
    font-weight: 600;
    position: relative;


  }

  &-login.cpm {
    background: linear-gradient(0, #2293FA 0%, #52AAFB 100%);
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(61, 232, 255, 1)) 1 1;
    border-image-width: 1px;
  }

  &-login.default {
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.2) 10%,
        rgba(25, 144, 182.8));
    border: 2rem solid rgba(255, 255, 255, 0.4);

    &::after {
      display: inline-flex;
      content: '';
      width: 2rem;
      background: rgba(255, 255, 255, 0.4);
      position: absolute;
      right: -2rem;
      top: -86rem;
      bottom: -999rem;
    }
  }

  &-banner {
    height: 100%;
    position: relative;

    &.three {
      background: url("@/assets/imgs/b1.png") no-repeat center top;
      background-size: 100% 100%;
      height: 620rem;
    }

    img,
    video {
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    video {
      width: 100%;
      object-fit: fill;
      z-index: auto;
    }

    &__image {
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    .l-mid {
      box-sizing: border-box;
      height: 100%;
      padding: 188rem 0 60rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h1 {
        font-size: 100rem;
        color: #fff;
        font-weight: bold;
      }

      h2 {
        font-size: 60rem;
        color: #fff;
        font-weight: 500;
      }
    }

    &__pmcontent {
      display: flex;
      flex-direction: column;

      &.grass {
        align-items: center;
        justify-content: flex-start;
        align-self: flex-start;
        height: 100%;
        padding: 0 190rem;

        h1 {
          color: #226884;
          font-family: 新愚公装甲宋A;
          font-size: 60rem;
        }

        p {
          font-size: 18rem;
          color: #73757D;
          margin: 10rem auto;
        }

        button {
          background: url("@/assets/imgs/btn1.png") no-repeat center center !important;
          background-size: 100% 100% !important;
          border-radius: 0;
          border: none;
          width: 170rem;
          height: 65rem;
        }
      }

      >h1 {
        font-size: 56rem !important;
        font-weight: normal !important;
        color: #fff;
      }

      >p {
        margin-top: 26rem;
        font-size: 16rem;
        color: #fff;
        line-height: 1.5;
      }

      button {
        outline: none;
        background: transparent;
        height: 62rem;
        padding: 0 36rem;
        border-radius: 31rem;
        border: 2rem solid #fff;
        display: inline-flex;
        align-items: center;
        font-size: 24rem;
        color: #fff;
        box-sizing: border-box;
        cursor: pointer;

        &[type='success'] {
          background: #2de5dc;
          border-color: #2de5dc;
        }

        &:active {
          opacity: 0.7;
        }
      }
    }
  }

  &-instro {
    display: flex;
    flex-direction: column;

    p {
      font-size: 16rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 30rem;
      margin-top: 32rem;
    }

    // display: flex;
    // align-items: flex-end;
    // height: 100%;
    // padding-bottom: 40rem;

    // &__title {
    //   box-sizing: border-box;
    //   flex: 1.25;

    //   .name {
    //     background: url('@/assets/images/introduce.png') no-repeat left top /
    //       100% auto;
    //     margin-top: 100rem;
    //     font-size: 55rem;
    //     color: #ffffff;
    //     font-weight: 800;
    //     text-align: center;
    //     box-sizing: border-box;
    //     padding-top: 90rem;
    //     padding-bottom: 20rem;
    //   }
    // }

    // .instro-text {
    //   flex: 1;
    //   padding-left: 40px;
    //   overflow: hidden;
    //   font-size: 16rem;
    //   font-weight: 400;
    //   color: #ffffff;
    //   line-height: 30rem;

    //   p {
    //     margin-top: 30rem;
    //   }
    // }
  }

  &-swiper {
    height: 100%;

    &__item {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &--banner {
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
      }

      .l-mid {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 188rem 0 120rem;

        h1 {
          font-size: 124rem;
          color: #fff;
          font-weight: 600;
        }

        h2 {
          font-size: 74rem;
          color: #fff;
          font-weight: 500;
        }

        h3 {
          font-size: 60rem;
          color: #fff;
          font-weight: 600;
        }

        .desc {
          font-size: 16rem;
          color: #fff;
          line-height: 2;
          letter-spacing: 1.2rem;
        }

        .button {
          display: inline-flex;
          font-size: 18rem;
          height: 48rem;
          padding: 0 24rem;
          background: #2de5dc;
          border-radius: 24rem;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          line-height: 1;

          img {
            width: 24rem;
          }
        }
      }

      // .l-introduce {
      //   background: rgba(255, 255, 255, 0.3);
      //   backdrop-filter: blur(17rem);
      //   display: flex;
      //   align-items: center;
      //   justify-content: space-between;
      //   padding: 48rem 28rem;

      //   &__title {
      //     background: url('@/assets/images/introduce.png') no-repeat center /
      //       cover;

      //     p {
      //       font-size: 16rem;
      //       font-weight: 500;
      //       color: #ffffff;
      //       margin: 0 0 14rem;
      //     }

      //     h3 {
      //       font-size: 38rem;
      //       color: #ffffff;
      //       font-weight: 800;
      //       white-space: nowrap;
      //     }
      //   }

      //   &__line {
      //     width: 1rem;
      //     height: 100%;
      //     background: rgba(255, 255, 255, 0.3);
      //     margin: 0 24rem;
      //   }

      //   &__content {
      //     box-sizing: border-box;
      //     font-size: 16rem;
      //     font-weight: 400;
      //     color: #ffffff;
      //     line-height: 30rem;
      //     margin-right: 24rem;
      //   }

      //   &__more {
      //     a {
      //       display: inline-flex;
      //       font-size: 18rem;
      //       width: 124rem;
      //       height: 48rem;
      //       background: #2de5dc;
      //       border-radius: 24rem;
      //       align-items: center;
      //       justify-content: center;
      //       color: #ffffff;

      //       img {
      //         width: 24rem;
      //       }
      //     }
      //   }
      // }
    }

    .swiper {
      &-container {
        height: 100%;
      }

      &-slide {
        height: 100%;
      }
    }

    &::v-deep .swiper-pagination-bullet {
      width: 200rem;
      height: 6rem;
      background: #fff;
      border-radius: 0;
      margin: 0 12rem;
      transition: 0.3s;
      margin: 0 !important;
    }

    &::v-deep .swiper-pagination-bullet-active {
      background: #fff;
    }

    &::v-deep .swiper-pagination-bullets {
      left: 50%;
      transform: translateX(-50%);
      bottom: 96rem;
      z-index: 999;
      width: 1200rem;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 0 8rem;
    }
  }

  &-subNav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 54rem;
    background: #fff;
    box-shadow: 0rem 8rem 12rem 0rem rgba(113, 145, 177, 0.56);
    border: 1rem solid #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 14rem 16rem;
    gap: 12rem 0;
    transition: 0.3s;
    z-index: -1;
    opacity: 0;

    &::before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: -60rem;
      width: 100%;
      height: 60rem;
    }

    li {
      font-size: 14rem;
      color: #7f899d;
      text-align: center;
      white-space: nowrap;
      cursor: pointer;
      transition: 0.3s;

      &.active {
        color: #1a8bf9;
        font-weight: 600;
      }

      &:hover {
        color: #1a8bf9;
        font-weight: 600;
      }
    }
  }

  &-contact {
    display: flex;
    background: #fff;
    border-radius: 0 100rem 0 0;
    overflow: hidden;

    &__banner {
      width: 610rem;
    }

    &__main {
      width: 810rem;
      display: flex;
      flex-direction: column;
      padding: 46rem 58rem;
    }
  }

  &-form {
    margin-top: 40rem;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20rem 14rem;

    &__item {
      width: calc((100% - 14rem) / 2);
      font-size: 0;

      input,
      select,
      textarea {
        min-width: auto;
      }
    }
  }

  &-center {
    position: relative;
    margin: 0 24rem 0 42rem;

    &__avatar {
      width: 52rem;
      height: 52rem;
      border-radius: 50%;
      border: 1rem solid #fff;
      background: linear-gradient(180deg,
          rgba(255, 255, 255, 0.4),
          rgba(61, 232, 255, 0.6));
      display: inline-flex;
      align-items: center;
      justify-content: center;

      img {
        // width: calc(100% - 12rem);
        width: 100%;
        border-radius: 50%;
      }
    }

    &:hover {
      .l-center__subNav {
        z-index: 101;
        opacity: 1;
      }
    }

    &__subNav {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 60rem;
      width: 148rem;
      background: #fff;
      box-shadow: 0rem 8rem 12rem 0rem rgba(113, 145, 177, 0.56);
      border: 1rem solid #fff;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      transition: 0.3s;
      z-index: -1;
      opacity: 0;

      &::before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: -60rem;
        width: 100%;
        height: 60rem;
      }

      p {
        font-size: 16rem;
        color: #1a1c1f;
        font-weight: 500;
        margin-top: 14rem;
        padding: 0 20rem;
      }

      li {
        font-size: 14rem;
        color: #7f899d;
        padding: 14rem 20rem;
        cursor: pointer;
        transition: 0.3s;

        &.active {
          color: #1a8bf9;
          font-weight: 600;
        }

        &:hover {
          color: #1a8bf9;
          font-weight: 600;
          background: #f0f3f5;
        }
      }
    }
  }
}

::v-deep .ant-select-selection {
  min-width: 344rem;
  width: 100%;
  height: 48rem;
  // background: #f3f6f9 url("@/assets/images/arrow_down.png") no-repeat right
  //   18rem center/ 20rem 20rem;
  border-radius: 24rem;
  border: 1rem solid #c2d1e0;
  outline: none;
  padding: 0 8rem;
  margin: 0;
  box-sizing: border-box;
  font-size: 14rem;
  color: #1a1c1f;
  appearance: none;
  background: #f3f6f9;
}

::v-deep .ant-select-selection__rendered {
  height: 48rem;
  line-height: 48rem;
}

::v-deep .ant-cascader-input.ant-input {
  width: 100%;
  height: 48rem;
  // background: #f3f6f9 url("@/assets/images/arrow_down.png") no-repeat right
  //   18rem center/ 20rem 20rem;
  border-radius: 24rem;
  border: 1rem solid #c2d1e0;
  outline: none;
  padding: 0 18rem;
  margin: 0;
  box-sizing: border-box;
  font-size: 14rem;
  color: #1a1c1f;
  appearance: none;

  // background: #f3f6f9;
}

::v-deep .ant-cascader-picker {
  background: #f3f6f9;
  border-radius: 24rem;
}
</style>
