<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <l-header v-if="showHeader($route.path)" />
      <router-view />
      <l-footer v-if="showFooter($route.path)" />
      <l-fixed />
    </div>
  </a-locale-provider>
</template>
<script>
import LHeader from '@/components/LHeader.vue'
import LFooter from '@/components/LFooter.vue'
import LFixed from '@/components/LFixed.vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
  components: {
    LHeader,
    LFooter,
    LFixed,
  },
  data() {
    return {
      locale: zhCN,
    }
  },
  filters: {},
  methods: {
    //是否显示头部（true显示，false不显示）
    showHeader(path) {
      if (path.indexOf('/BookSeriesSubscription') > -1 || path == '/mobile/zd' ){
        window.document.title = '《城市基层治理实务》丛书征订';
      }else{
        window.document.title = '一湾社会发展研究院';
      }
      if (
        path == '/login' ||
        path == '/org/register' ||
        path == '/org/forget' ||
        path == '/live/conference/login' ||
        path == '/live/conference/org/register' ||
        path == '/live/conference/org/forget' ||
        path == '/BookSeriesSubscription'
      ) {
        return false
      } else {
        return true
      }
    },
    showFooter (path) {
      if (path.startsWith('/BookSeriesSubscription') || path.startsWith('/mobile')) {
        return false
      }
      return true;
    }
  },
}
</script>

<style lang="less">
.ant-modal-root {
  &.radius {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-content {
      border-radius: 0 68rem 0 0;
    }
    .ant-modal-close {
      top: 28rem;
      right: 28rem;
    }
  }
}
</style>
