import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Bus from '@/bus/index'

// css
import 'ant-design-vue/dist/antd.css'
import './assets/styles/common.less'
import 'swiper/dist/css/swiper.css'
import 'vant/es/cascader/index.css'
// components
import {
  Button,
  message,
  Pagination,
  LocaleProvider,
  Row,
  Col,
  Select,
  Cascader,
  InputNumber,
  Space,
  Modal,
  FormModel,
  Radio,
  Input,
  DatePicker,
  Breadcrumb,
  Empty,
  Checkbox,
  Tooltip,
  Form,
  Carousel,
  Upload,
  Tabs,
} from 'ant-design-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.use(Tabs)
Vue.use(Carousel)
Vue.use(Button)
Vue.use(Pagination)
Vue.use(LocaleProvider)
Vue.use(Row)
Vue.use(Col)
Vue.use(Select)
Vue.use(Cascader)
Vue.use(InputNumber)
Vue.use(Space)
Vue.use(Space)
Vue.use(Modal)
Vue.use(FormModel)
Vue.use(Radio)
Vue.use(Input)
Vue.use(DatePicker)
Vue.use(Breadcrumb)
Vue.use(Empty)
Vue.use(VueAwesomeSwiper)
Vue.use(Checkbox)
Vue.use(Tooltip)
Vue.use(Form)
Vue.use(Bus)
Vue.use(Upload)
Vue.config.productionTip = false

function toDecimal(x) {
  var f = parseFloat(x)
  if (isNaN(f)) {
    return
  }
  f = Math.round(x * 100) / 100
  return f
}
Vue.filter('courseTime', function (value) {
  var url = location.href
  if (url.indexOf('OUCStudy') != -1) {
    if (!value) {
      value = 0
    }
    var showtime = ''
    var _hours = parseFloat(value) / 50
    var _hours = toDecimal(_hours).toString()
    if (_hours != null && _hours > 0) {
      showtime += _hours + '学时'
    } else {
      showtime = '0学时'
    }
  } else {
    if (!value) {
      value = 0
    }
    var showtime = ''
    var _hours = parseFloat(value) / 60
    var _hours = toDecimal(_hours).toString()
    if (_hours != null && _hours > 0) {
      showtime += _hours + '小时'
    } else {
      showtime = '0小时'
    }
  }
  //@by oucstudy 要求
  /*     var _hours = parseInt(value / 60);
  var _minutes = value - _hours * 60;
  if (_hours != null && _hours > 0) {
      showtime += _hours + "学时";
  }*/
  /* if (_hours != null && _hours > 0) {
      showtime += _hours + "小时";
  }*/
  /* if (_minutes != null) {
      showtime += _minutes + "分钟";
  }*/
  return showtime
})
Vue.filter('courseFee', function (value) {
  // 返回处理后的值
  if (value == 0) {
    return '免费'
  }
  if (!value) {
    return '价格待定'
  }
  return '¥' + value
})
Vue.prototype.$message = message
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$success = Modal.success
Vue.prototype.$warning = Modal.warning
Vue.prototype.$error = Modal.error
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
